<template>
  <div id="faqs">
    <FAQs />
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Faqs Page',
  components: {
    FAQs: defineAsyncComponent(() => import('@/components/FAQs.vue')),
  },
}
</script>
